.Navbar{
    background-color: black;
    width: 100%;
    height: 80px;
    display: flex;
}
.left-side-empty{
    display: flex;
    flex: 0%;
}
.Navbar .leftSide{
    flex: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.Navbar .leftSide .links{
    max-height: 80px;
    padding-left: 50px;
}

.Navbar .leftSide .links a {
    text-decoration: none;
    color: white;
    font-size: 25px;
    margin-left: 15px;
}

.Navbar .rightSide{
    flex: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 100px;
}

.Navbar .leftSide #hidden{
    display: flex;
}

.leftSide{
    margin-left: 30px;
}

.Navbar .leftSide .links a {
    font-size: 18px;
}

/* .openButton{
    display: none;
    margin-left: 20px;
} */

.xs-menu-btn{
    display: none;
}

.bars{
    display : none;
    color : #fff;
}

@media only screen and (max-width:550px){
    /* show button logic here */
    /* .openButton{
        display:flex;
        margin-left: 20px;
    } */

    .bars{
        display : block;
        position: absolute;
        top : 0;
        right : 0;
        transform : translate(-100%, 75%);
        font-size : 1.8rem;
        cursor : pointer;
    }

    .Navbar .leftSide .links{
        display: none;
        padding: 0px;
    }

    .Navbar .rightSide .rlinks{
        display: none;
    }

    .Navbar .leftSide #hidden{
        position: absolute;
        left: 0px;
        top: 80px;
        height: 230px;
        max-height: 330px;
        width: 100%;
        background-color: black;
        display: flex;
        flex-direction: column;
    }

    .Navbar .leftSide .links a {
        margin: 10px 20px;
        border-bottom: 1px solid gray;
        padding-bottom: 7px;
    }

    .hideXs{
        display: none !important;
    }

    .xs-menu-btn{
        display: flex;
        margin: 10px 20px;
    }

    .left-side-empty{
        display: none;
    }


}